
export const profile = '/lrcpn-panel/profile/'
export const profileStore = profile + 'store'
export const profileUpdate = profile + 'update'
export const profileShow = profile + 'details'

// Formalin Lincese Application List Show
export const formalinLicenseApplication = '/lrcpn-service-panel/formalin-license-application'
export const applicationList = formalinLicenseApplication + '/list'
export const applicationShowApi = formalinLicenseApplication + '/show'
export const applicantInfoStore = formalinLicenseApplication + '/applicant-info-sotre'
export const applicantInfoUpdate = formalinLicenseApplication + '/applicant-info-update'
export const businessInfoStore = formalinLicenseApplication + '/business-info-sotre'
export const businessInfoUpdate = formalinLicenseApplication + '/business-info-update'
export const appPaymentStore = formalinLicenseApplication + '/payment-store'
export const allAppReceipt = formalinLicenseApplication + '/all-receipt'
export const formalinImportApplicationCertificateApi = formalinLicenseApplication + '/certificate'

// application payment online
const payment = '/lrcpn-service-panel/formalin-license-payment/'
export const paymentOnlineStore = payment + 'online/store'
export const paymentOnlineSuccess = payment + 'online/success'
export const paymentOnlineDecline = payment + 'online/decline'
export const paymentOnlineCancle = payment + 'online/cancle'
export const tcbPaymentStatusUpdate = payment + 'online/update'
export const paymentOfflineStore = payment + 'offline/store'
export const paymentRecepetAll = payment + 'offline/receipt'

// Formalin Clearance Application List Show
export const formalinClearanceApplication = '/lrcpn-service-panel/formalin-clearance-application'
export const clearanceApplicationList = formalinClearanceApplication + '/list'
export const clearanceApplicationShowApi = formalinClearanceApplication + '/show'
export const clearanceApplicantInfoStore = formalinClearanceApplication + '/applicant-info-sotre'
export const clearanceApplicantInfoUpdate = formalinClearanceApplication + '/applicant-info-update'
export const clearanceBusinessInfoStore = formalinClearanceApplication + '/business-info-sotre'
export const clearanceAppPaymentStore = formalinClearanceApplication + '/payment-store'
export const allClearanceAppReceipt = formalinClearanceApplication + '/all-receipt'
export const formalinImportClearaneCertificateApi = formalinClearanceApplication + '/certificate'

// Acid License Clearance Applicaiton
export const acidLicenseClearanceApplication = '/lrcpn-service-panel/acid-license-clearance-application/'
export const acidLicenseClearanceApplicationListApi = acidLicenseClearanceApplication + 'list'
export const acidLicenseClearanceApplicationAdminListApi = acidLicenseClearanceApplication + 'admin-list'
export const acidLicenseClearanceApplicantInfoStoreApi = acidLicenseClearanceApplication + 'store'
export const acidLicenseClearanceAttachmentStoreApi = acidLicenseClearanceApplication + 'attachment-store'
export const acidLicenseClearanceApplicantInfoUpdateApi = acidLicenseClearanceApplication + 'update'
export const acidLicenseClearanceApplicationShowApi = acidLicenseClearanceApplication + 'show'
// Acid Import Clearance Applicaiton
export const acidImportClearanceApplication = '/lrcpn-service-panel/acid-import-clearance-application/'
export const acidImportClearanceApplicationListApi = acidImportClearanceApplication + 'list'
export const acidImportClearanceApplicationAdminListApi = acidImportClearanceApplication + 'admin-list'
export const acidImportClearanceApplicantInfoStoreApi = acidImportClearanceApplication + 'store'
export const acidImportClearanceBusinessInfoStoreApi = acidImportClearanceApplication + 'store-business-info'
export const acidImportClearanceApplicantInfoUpdateApi = acidImportClearanceApplication + 'update'
export const acidImportClearanceApplicationShowApi = acidImportClearanceApplication + 'show'
export const acidImportClearaneCertificateApi = acidImportClearanceApplication + 'certificate'
