<template>
  <div class="application-itmes">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loading">
          <b-row>
              <!-- business address info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.business_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                            <b-row>
                              <!-- business address -->
                              <b-col sm="6">
                                  <ValidationProvider name="Business Address (En)" vid="business_address" rules="">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="business_address"
                                    >
                                      <template v-slot:label>
                                        {{ $t('lrcpnServicePanel.business_address') }} {{ $t('globalTrans.en') }}
                                      </template>
                                      <b-form-textarea
                                        id="business_address"
                                        v-model="formData.business_address"
                                        rows="1"
                                        max-rows="2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- business_address_bn -->
                              <b-col sm="6">
                                  <ValidationProvider name="Business Address (Bn)" vid="business_address_bn" rules="required">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="business_address_bn"
                                    >
                                      <template v-slot:label>
                                        {{ $t('lrcpnServicePanel.business_address') }}  {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-textarea
                                        id="business_address_bn"
                                        v-model="formData.business_address_bn"
                                        rows="1"
                                        max-rows="2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                               <!-- business address -->
                               <b-col sm="6">
                                  <ValidationProvider name="Purpose of Acid import" vid="purpose_of_acid_import" rules="">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="purpose_of_acid_import"
                                    >
                                      <template v-slot:label>
                                        {{ $t('lrcpnServicePanel.purpose_of_import') }}
                                      </template>
                                      <b-form-textarea
                                        id="purpose_of_acid_import"
                                        v-model="formData.purpose_of_acid_import"
                                        rows="1"
                                        max-rows="2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- business_telephone_number -->
                              <b-col sm="6">
                                <ValidationProvider name="Business Telephone No." vid="business_telephone_number" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="business_telephone_number"
                                    >
                                        <template v-slot:label>
                                            {{ $t('externalUser.telephone_no') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formData.business_telephone_number"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- drug_license_info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.drug_license_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- license_no -->
                                <b-col sm="6">
                                  <ValidationProvider name="Drug License No." vid="license_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="license_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.drug_license_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formData.drug_license_info.license_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                  </b-col>
                                  <!-- license_provider -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Drug License Issuing Authority" vid="license_provider" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="license_provider"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.drug_license_provider') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="license_provider"
                                              v-model="formData.drug_license_info.license_provider"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                   <!-- license_issue_date -->
                                   <b-col sm="6">
                                      <ValidationProvider name="Drug Licnese Issue Date" vid="license_issue_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="license_issue_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.drug_license_issue_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="license_issue_date"
                                              v-model="formData.drug_license_info.license_issue_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- license_expire_date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Drug Licnese Expiry Date" vid="license_expire_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="license_expire_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.drug_license_expire_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="license_expire_date"
                                              v-model="formData.drug_license_info.license_expire_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
               <!-- trade_license_info -->
               <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.trade_license_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <!-- license_info -->
                              <b-row>
                                <!-- license_no -->
                                  <b-col sm="6">
                                  <ValidationProvider name="Trade License No." vid="license_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="license_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formData.trade_license_info.license_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                  </b-col>
                                  <!-- license_type -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Type" vid="license_type" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="license_type"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_type') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="license_type"
                                              v-model="formData.trade_license_info.license_type"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- license_provider -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Issuing Authority" vid="license_provider" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="license_provider"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_provider') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="license_provider"
                                              v-model="formData.trade_license_info.license_provider"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- license_issue_address -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Issue Place" vid="license_issue_address" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="license_issue_address"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_issue_place') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="license_issue_address"
                                              v-model="formData.trade_license_info.license_issue_address"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- license_expire_date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade Licnese Expiry Date" vid="license_expire_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="license_expire_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.trade_license_expire_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="license_expire_date"
                                              v-model="formData.trade_license_info.license_expire_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- tax_info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.tax_information')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- tin_no -->
                                 <b-col sm="6">
                                  <ValidationProvider name="TIN No." vid="tin_no" :rules="{ required: true, tinLength: true }">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="tin_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('externalUser.tin_no') }}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formData.tax_info.tin_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                 </b-col>
                                 <!-- circle -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Circle" vid="circle" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="circle"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.circle') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="circle"
                                              v-model="formData.tax_info.circle"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- zone -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Zone" vid="zone" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="zone"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.zone') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="zone"
                                              v-model="formData.tax_info.zone"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- last_two_years_tax_attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Tax certificate attachment" :rules="attachValidation">
                                      <b-form-group
                                        label-for="last_two_years_tax_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.last_two_year_tax_paid_amount') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="last_two_years_tax_attachment"
                                            v-model="formData.tax_info.last_two_years_tax_attachment"
                                            @change="onFileChangeTaxInfo($event)"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.tax_info.last_two_years_tax_attachment && checkBase64(formData.tax_info.last_two_years_tax_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.tax_info.last_two_years_tax_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- warehouse_info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.warehouse_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                  <!-- address_bn-->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Address (En)" vid="address" rules="">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="address"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_address') }} {{ $t('globalTrans.en') }}
                                          </template>
                                          <b-form-textarea
                                            id="address"
                                            v-model="formData.warehouse_info.address"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- address_bn -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Address (Bn)" vid="address_bn" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="address_bn"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_address') }}  {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="address_bn"
                                            v-model="formData.warehouse_info.address_bn"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- description -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Details" vid="description" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="description"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_details') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="description"
                                            v-model="formData.warehouse_info.description"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- description_bn -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Details" vid="description_bn" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="description_bn"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_details') }} {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="description_bn"
                                            v-model="formData.warehouse_info.description_bn"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- warehouse capacity -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Warehouse Capacity" vid="capacity" rules="required">
                                      <b-form-group
                                      slot-scope="{ valid, errors }"
                                      label-for="capacity"
                                      >
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.warehouse_capacity') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          v-model="formData.warehouse_info.capacity"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- attachement info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.attachment_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- imported_acid_name_details_attachment-->
                                  <b-col sm="6">
                                    <ValidationProvider name="Details of what acid you want to import along with justification of demand, its scientific name, commercial name, formula and quantity (separate sheet with signature and seal may be attached if necessary)" vid="imported_acid_name_details_attachment " :rules="attachValidation">
                                      <b-form-group
                                        label-for="imported_acid_name_details_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.imported_acid_name_details_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="imported_acid_name_details_attachment"
                                            v-model="formData.imported_acid_name_details_attachment"
                                            @change="onFileChange($event, 'imported_acid_name_details_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.imported_acid_name_details_attachment && checkBase64(formData.imported_acid_name_details_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.imported_acid_name_details_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- lc_bank_loan_details_for_import_attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="LC, Bank Loan details Attachment" vid="lc_bank_loan_details_for_import_attachment " :rules="attachValidation">
                                      <b-form-group
                                        label-for="lc_bank_loan_details_for_import_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.bank_lc_details') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="lc_bank_loan_details_for_import_attachment"
                                            v-model="formData.lc_bank_loan_details_for_import_attachment"
                                            @change="onFileChange($event, 'lc_bank_loan_details_for_import_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.lc_bank_loan_details_for_import_attachment && checkBase64(formData.lc_bank_loan_details_for_import_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.lc_bank_loan_details_for_import_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- recently_imported_acid_info_detail_attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Recently imported acid info detail attachment" :rules="attachValidation">
                                      <b-form-group
                                        label-for="recently_imported_acid_info_detail_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.recently_imported_acid_info_detail_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="recently_imported_acid_info_detail_attachment"
                                            v-model="formData.recently_imported_acid_info_detail_attachment"
                                            @change="onFileChange($event, 'recently_imported_acid_info_detail_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.recently_imported_acid_info_detail_attachment && checkBase64(formData.recently_imported_acid_info_detail_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.recently_imported_acid_info_detail_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </ValidationObserver>
    <!-- rest of import infomariton -->
    <b-col sm="12">
        <div class="group-form-card">
            <b-card>
                <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.acid_import_info')" label-size="lg"
                    label-class="font-weight-bold pt-0" class="mb-0">
                    <b-row>
                      <ValidationObserver ref="add_more_data" v-slot="{ handaleAddMoreData, reset }">
                        <b-form @submit.prevent="handaleAddMoreData(addMore)" @reset.prevent="reset">
                            <b-row>
                              <div>
                                  <!-- add more -->
                                  <b-row>
                                  <!-- acid_id -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Acid Name" vid="acid_id" rules="required|min_value:1">
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="acid_id">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.acid_name') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                              plain
                                              v-model="addMoreData.acid_id"
                                              id="acid_id"
                                              :options="masterAcidNameList"
                                              :state="errors[0] ? false : (valid ? true : null)">
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- Quantity  -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Quantity" vid="qty" :rules="{required:true, min_value: 1}">
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="qty">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.qty') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          @keypress="isNumber"
                                          id="qty"
                                          v-model.number="addMoreData.qty"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- Price -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Price" vid="price" :rules="{required:true, min_value: 1}" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="price">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.price') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          @keypress="isNumber"
                                          id="price"
                                          v-model.number="addMoreData.price"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                              <div class="d-flex justify-content-end">
                                <b-button type="button" v-if="is_edit" variant="danger" class="mr-1 btn-sm" @click="cancelItem()"><i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}</b-button>
                                <b-button type="button" @click="addMore" variant="success" class="mr-2 btn-sm"> <i class="ri-file-add-fill"></i> {{ is_edit ? $t('globalTrans.update') : $t('globalTrans.add_more') }}</b-button>
                              </div>
                              <hr>
                              <!-- table data show -->
                              <b-overlay :show="addMoreLoading">
                              <b-table-simple style="width: 100%" bordered>
                                  <b-tr>
                                      <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.acid_name') }}</b-th>
                                      <b-th class="text-center" style="width: 15%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                      <b-th class="text-center" style="width: 15%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                      <b-th class="text-center" style="width: 20%;"> {{ $t('globalTrans.action') }}</b-th>
                                  </b-tr>
                                  <template v-if="formData.acid_import_worth_value_infos.length">
                                      <slot v-for="(item, index) in formData.acid_import_worth_value_infos">
                                          <b-tr :key="index">
                                              <b-td class="text-center">
                                                  {{ getAcidName(item.acid_id) }}
                                              </b-td>
                                              <b-td class="text-center">
                                                  {{ $n(item.qty, {useGrouping: false}) }}
                                              </b-td>
                                              <b-td class="text-center">
                                                  {{ $n(item.price, {useGrouping: false}) }}
                                              </b-td>
                                              <b-td>
                                                <div class="d-flex justify-content-end">
                                                <b-button @click="editAddMore(index, item)" class="btn btn-sm btn-info"><i class="ri-edit-box-line"></i></b-button>
                                                <b-button type="button" @click="removeAddMore(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                              </div>
                                              </b-td>
                                          </b-tr>
                                      </slot>
                                      <b-tr>
                                          <b-td colspan="2" class="text-right">{{$t('globalTrans.total')}}</b-td>
                                          <b-td class="text-center">{{ $n(totalAmount) }}</b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-table-simple>
                              </b-overlay>
                              </div>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    </b-row>
                    <!-- import_details -->
                    <div>
                      <h5>{{ $t('lrcpnServicePanel.import_details') }}</h5>
                    </div>
                    <hr>
                    <b-row>
                      <!-- import_purpose -->
                      <b-col sm="6">
                            <ValidationProvider name="Import Purpose" vid="purpose" rules="required">
                              <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="purpose"
                              >
                                <template v-slot:label>
                                  {{ $t('lrcpnServicePanel.import_purpose') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                  id="purpose"
                                  v-model="formData.present_import_info.purpose"
                                  rows="1"
                                  max-rows="2"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- import_route -->
                        <b-col sm="6">
                          <ValidationProvider name="Import Route" vid="route" rules="required" >
                          <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="route">
                              <template v-slot:label>
                              {{ $t('lrcpnServicePanel.import_route') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="route"
                              v-model="formData.present_import_info.route"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- import_port_name -->
                        <b-col sm="6">
                          <ValidationProvider name="Entry Sea/Air/Land-Port" vid="access" rules="required" >
                          <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="access">
                              <template v-slot:label>
                              {{ $t('lrcpnServicePanel.import_port_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="access"
                              v-model="formData.present_import_info.access"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- possible_time -->
                        <b-col sm="6">
                            <ValidationProvider name="Estimated Date Of Import" vid="possible_time" rules="required">
                                <b-form-group
                                class="row"
                                label-for="possible_time"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                    {{ $t('lrcpnServicePanel.estimated_date_of_import') }}  <span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    id="possible_time"
                                    v-model="formData.present_import_info.possible_time"
                                    class="form-control"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </date-picker>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- have_previous_license -->
                        <b-col sm="12">
                          <ValidationProvider name="Already have a license" vid="have_previous_license" rules="required">
                              <b-form-group
                                class
                                label-for="have_previous_license"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                <div style="font-weight: bold">
                                  {{ $t('lrcpnServicePanel.have_previous_license') }}
                                </div>
                              </template>
                              <b-form-radio-group
                                style="margin-top:5px;"
                                :id="have_previous_license"
                                v-model="formData.previous_license_info.have_previous_license"
                                :options="valueOptions"
                              ></b-form-radio-group>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- previous_license_number -->
                        <b-col sm="6" v-if="formData.previous_license_info.have_previous_license == 1">
                          <ValidationProvider name="Previous License No." vid="previous_license_number" rules="required">
                            <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="previous_license_number"
                            >
                                <template v-slot:label>
                                    {{ $t('lrcpnServicePanel.previous_license_number') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                v-model="formData.previous_license_info.previous_acid_import_license_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- previous_license_provide_date -->
                        <b-col sm="6" v-if="formData.previous_license_info.have_previous_license == 1">
                            <ValidationProvider name="Previous License Issue Date" vid="previous_license_provide_date" rules="required">
                                <b-form-group
                                class="row"
                                label-for="previous_license_provide_date"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                    {{ $t('lrcpnServicePanel.previous_license_provide_date') }}  <span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    id="previous_license_provide_date"
                                    v-model="formData.previous_license_info.previous_acid_import_license_issue_date"
                                    class="form-control"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </date-picker>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- is_previous_license_reject -->
                        <b-col sm="6">
                          <ValidationProvider name="Has the license been canceled before?" vid="has_previous_license_rejected" rules="required">
                              <b-form-group
                                class
                                label-for="is_previous_license_reject"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                <div style="font-weight: bold">
                                  {{ $t('lrcpnServicePanel.has_previous_license_rejected') }}
                                </div>
                              </template>
                              <b-form-radio-group
                                style="margin-top:5px;"
                                :id="is_previous_license_reject"
                                v-model="formData.is_previous_license_reject"
                                :options="valueOptions"
                              ></b-form-radio-group>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- previous_license_rejected_reasons -->
                        <b-col sm="6" v-if="formData.is_previous_license_reject == 1">
                            <ValidationProvider name="Rejected Reasons" vid="previous_license_rejected_reasons " rules="required">
                              <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="previous_license_rejected_reasons "
                              >
                                <template v-slot:label>
                                  {{ $t('lrcpnServicePanel.rejected_reasons') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                  id="previous_license_rejected_reasons "
                                  v-model="formData.previous_license_reject_reasons "
                                  rows="1"
                                  max-rows="2"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- import_helping_agent_name -->
                        <b-col sm="6">
                          <ValidationProvider name="Name of indenture/agent assisting in importation" vid="import_agent_name" rules="required" >
                          <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="import_agent_name">
                              <template v-slot:label>
                              {{ $t('lrcpnServicePanel.import_helping_agent_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="import_agent_name"
                              v-model="formData.import_agent_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- import_helping_agent_address -->
                        <b-col sm="6">
                            <ValidationProvider name="Address of indenture/agent assisting in importation" vid="import_agent_address" rules="required">
                              <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="import_agent_address"
                              >
                                <template v-slot:label>
                                  {{ $t('lrcpnServicePanel.import_helping_agent_address') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                  id="import_agent_address"
                                  v-model="formData.import_agent_address"
                                  rows="1"
                                  max-rows="2"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- any_case_field_under_acis_control_act -->
                        <b-col sm="12">
                          <ValidationProvider name="Have you suffered any punishment under the Acid Control Act?" vid="has_case" rules="required">
                              <b-form-group
                                class
                                label-for="has_case"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                <div style="font-weight: bold">
                                  {{ $t('lrcpnServicePanel.any_case_field_under_acis_control_act') }}
                                </div>
                              </template>
                              <b-form-radio-group
                                style="margin-top:5px;"
                                :id="has_case"
                                v-model="formData.any_case_field_under_acis_control_act.has_case"
                                :options="valueOptions"
                              ></b-form-radio-group>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- case_no_and_section -->
                        <b-col sm="6" v-if="formData.any_case_field_under_acis_control_act.has_case == 1">
                            <ValidationProvider name="Case No. and Section" vid="case_no_and_section " rules="required">
                              <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="case_no_and_section "
                              >
                                <template v-slot:label>
                                  {{ $t('lrcpnServicePanel.case_no_and_section') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                  id="case_no_and_section "
                                  v-model="formData.any_case_field_under_acis_control_act.case_number_section"
                                  rows="1"
                                  max-rows="2"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- date_of_case_judgment -->
                        <b-col sm="6" v-if="formData.any_case_field_under_acis_control_act.has_case == 1">
                            <ValidationProvider name="Date of Case Judgment" vid="date_of_sentencing" rules="required">
                                <b-form-group
                                class="row"
                                label-for="date_of_sentencing"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                    {{ $t('lrcpnServicePanel.date_of_case_judgment') }}  <span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    id="date_of_sentencing"
                                    v-model="formData.any_case_field_under_acis_control_act.date_of_sentencing"
                                    class="form-control"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </date-picker>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- date_of_completion_of_sentence -->
                        <b-col sm="6" v-if="formData.any_case_field_under_acis_control_act.has_case == 1">
                            <ValidationProvider name="Date of Completion of Penalty" vid="date_of_completion" rules="required">
                                <b-form-group
                                class="row"
                                label-for="date_of_completion"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                    {{ $t('lrcpnServicePanel.date_of_completion_of_sentence') }}  <span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    id="date_of_completion"
                                    v-model="formData.any_case_field_under_acis_control_act.date_of_completion"
                                    class="form-control"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </date-picker>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-card>
        </div>
    </b-col>
  </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { acidImportClearanceBusinessInfoStoreApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
// Add the required rule
extend('required', required)
// Add the custom tinLength rule
extend('tinLength', {
  validate (value) {
    return value.length === 12
  },
  message: 'The TIN No. must be exactly 12 digits long'
})
export default {
props: ['tabFormData', 'draft'],
components: { },
data () {
  return {
    valid: null,
    attachValidation: '',
    formData: this.tabFormData,
    lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
    present_import_info: this.tabFormData.present_import_details,
    addMoreData: {
      acid_id: 0,
      qty: 0,
      price: 0
    },
    addMoreLoading: false,
    is_edit: false,
    valueOptions: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 0, text: this.$i18n.locale === 'en' ? 'No' : 'না' }
    ]
  }
},
created () {
},
mounted () {
  this.formData = this.tabFormData
},
computed: {
  loading: function () {
    return this.$store.state.commonObj.loading
  },
  currentLocale () {
    return this.$i18n.locale
  },
  totalAmount () {
      let total = 0
      this.formData.acid_import_worth_value_infos.forEach(item => {
          total += item.price
      })
      return total
  },
  masterAcidNameList () {
      return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
  }
},
watch: {
},
methods: {
    onFileChangeTaxInfo (event) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.formData.tax_info.last_two_years_tax_attachment = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
         this.formData.tax_info.last_two_years_tax_attachment = ''
      }
    },
    onFileChange (event, property) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.formData[property] = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.formData[property] = ''
      }
    },
    checkBase64 (string) {
      var result = ''
      result = string.match('data:')
      if (result) {
          return true
      } else {
          return false
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    async addMore () {
            this.addMoreLoading = true
            var check = await this.$refs.add_more_data.validate()
            if (check) {
                const existCheck = this.formData.acid_import_worth_value_infos.find(item => parseInt(item.acid_id) === parseInt(this.addMoreData.acid_id))

                if (existCheck !== undefined) {
                    this.$toast.error({
                        title: 'Warning',
                        message: this.$t('globalTrans.item_exist'),
                        color: '#ee5253'
                    })
                    this.addMoreLoading = false
                    return false
                }

                if (this.is_edit) {
                    this.formData.acid_import_worth_value_infos.splice(this.addMoreData.index, 1, this.addMoreData)
                } else {
                    this.formData.acid_import_worth_value_infos.push(this.addMoreData)
                }
                this.addMoreData = {}
                this.$refs.add_more_data.reset()
            }
            this.is_edit = false

            setTimeout(() => {
                this.addMoreLoading = false
            }, 100)
    },
    removeAddMore (index) {
        this.formData.acid_import_worth_value_infos.splice(index, 1)
    },
    editAddMore (index, item) {
        this.addMoreData = Object.assign({}, item)
        this.is_edit = true
        this.addMoreData.index = index
    },
    cancelItem () {
        this.addMoreData = {}
        this.is_edit = false
        this.$refs.add_more_data.reset()
    },
    getAcidName (id) {
        const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async submit () {
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const loadingState = { loading: false, listReload: false }
        const data = Object.assign({}, this.formData, { draft: this.draft })
        const result = await RestApi.postData(lrcpnServiceBaseUrl, acidImportClearanceBusinessInfoStoreApi, data)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          return result
        } else {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('globalTrans.form_error_msg'),
            color: '#ee5253'
          })
        }
      }
    }
}
}
</script>
