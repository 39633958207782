<template>
    <div class="application-itmes">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                <b-overlay :show="loading">
                    <b-row>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <!-- search for admin -->
                                            <b-col sm="12" v-if="authUser.user_type === 1">
                                                <div class="row">
                                                    <b-col sm="6">
                                                        <ValidationProvider name="Mobile No" vid="mobile" :rules="'max:11|min:11|numeric'">
                                                            <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="mobile">
                                                                <template v-slot:label>
                                                                    {{ $t('globalTrans.mobile') }}
                                                                </template>
                                                                <b-form-input
                                                                    @keypress="isNumber"
                                                                    type="text"
                                                                    v-model="search.mobile"
                                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="6">
                                                        <b-button type="button" @click="getExternalUserInfo()" variant="success" class="mt-3 btn-sm">{{ $t('globalTrans.search') }} <b-spinner v-if="isExternalUserLoading" variant="danger" small label="Spinning"></b-spinner></b-button>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                            <!-- applicant_name -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (En)" vid="applicant_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          :disabled="isExternalUserFind"
                                                          v-model="formData.applicant_name"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          :disabled="isExternalUserFind"
                                                          v-model="formData.applicant_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_mobile -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mobile" vid="applicant_mobile" :rules="'max:11|min:11|numeric|required'">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_mobile"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        :disabled="isExternalUserFind"
                                                        v-model="formData.applicant_mobile"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @keypress="isNumber"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- telephone_number -->
                                             <b-col sm="6">
                                                <ValidationProvider name="Applicant Telephone No." vid="telephone_no">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="telephone_no"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalUser.telephone_no') }}
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.telephone_number"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @keypress="isNumber"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_email -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Email" vid="applicant_email" rules="required|email">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_email"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.applicant_email"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant signature -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Signature" :rules="attachValidation">
                                                <b-form-group
                                                    label-for="applicant_signature"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('lrcpnServicePanel.applicant_signature') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <div class="d-flex">
                                                        <b-form-file
                                                        id="applicant_signature"
                                                        v-model="formData.applicant_signature"
                                                        @change="onFileChange($event, 'applicant_signature')"
                                                        accept=".jpg,.png,.jpeg"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :placeholder="$t('globalTrans.attachment_placeholder')"
                                                        :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                                        ></b-form-file>
                                                        <a target="_blank" style="margin-left: 8px;" v-if="formData.applicant_signature && checkBase64(formData.applicant_signature) === false" :href="lrcpnServiceBaseUrl  + formData.applicant_signature" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                                    </div>
                                                    <div class="invalid-feedback d-block">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- passport_no -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Passport No." vid="passport_no">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="passport_no"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalUser.passport_no') }}
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.passport_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- father_spouse_name -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Father's/Husband Name (En)" vid="father_spouse_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="father_spouse_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.father_spouse_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.father_spouse_name"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- father_spouse_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Father's/Husband Name (Bn)" vid="designation_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="father_spouse_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.father_spouse_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.father_spouse_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- mothers_name -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mother's Name (En)" vid="mothers_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="mothers_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.mothers_name"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- mothers_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mother's Name (Bn)" vid="mothers_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="mothers_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.mothers_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- nationality -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Nationality" vid="applicant_nationality" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_nationality"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.nationality') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.nationality"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.permanent_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <Address :key="address.area_type_id" :data.sync="address" :addressType="'address'" :param="fieldObj" @address-data="receivePermanentAddress" title="Permanent Address"/>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.present_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-col sm="12">
                                            <b-form-checkbox
                                                id="same_as_permanent"
                                                class="witness-agree-wrapper"
                                                size="lg"
                                                v-model="formData.same_as_permanent"
                                                name="same_as_permanent"
                                            ><b>{{ $t('lrcpnServicePanel.same_as_permanent') }}</b></b-form-checkbox>
                                        </b-col>
                                        <Address :key="presentAddress.area_type_id" :data.sync="presentAddress" :addressType="'address'" :param="fieldObj" @address-data="receivePresentAddress" title="Present Address"/>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { acidImportClearanceApplicantInfoStoreApi, acidImportClearanceApplicantInfoUpdateApi } from '../../api/routes'
import Address from '@/components/Address.vue'
import { helpers } from '@/utils/helper-functions'
import { mapGetters } from 'vuex'
export default {
    props: ['tabFormData'],
    components: { Address },
    data () {
        return {
            lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
            valid: null,
            invalidNid: 0,
            fieldObj: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 12,
                labelCols: 12,
                noCountry: true,
                noAddress: false,
                isPaurashavaNullable: true,
                isUnionNullable: true,
                hasPostCode: false,
                hasWardShow: false,
                isPostCodeNullable: true,
                noMargin: true
            },
            formData: {},
            address: {
              area_type_id: 0,
              division_id: 0,
              district_id: 0,
              city_corporation_id: 0,
              paurashava_id: 0,
              upazila_id: 0,
              union_id: 0,
              ward_id: 0,
              address_details_en: '',
              address_details_bn: ''
            },
            presentAddress: {
              area_type_id: 0,
              division_id: 0,
              district_id: 0,
              city_corporation_id: 0,
              paurashava_id: 0,
              upazila_id: 0,
              union_id: 0,
              ward_id: 0,
              address_details_en: '',
              address_details_bn: ''
            },
            search: {
                mobile: ''
            },
            isExternalUserLoading: false
        }
    },
    created () {
    },
    mounted () {
        this.formData = this.tabFormData
        this.address = {
            area_type_id: this.formData.applicant_permanent_area_type_id,
            division_id: this.formData.applicant_permanent_division_id,
            district_id: this.formData.applicant_permanent_district_id,
            city_corporation_id: this.formData.applicant_permanent_city_corporation_id,
            paurashava_id: this.formData.applicant_permanent_pauroshoba_id,
            upazila_id: this.formData.applicant_permanent_upazila_id,
            union_id: this.formData.applicant_permanent_union_id,
            address_details_en: this.formData.applicant_permanent_village_name,
            address_details_bn: this.formData.applicant_permanent_village_name_bn
        }
        this.$set(this.presentAddress, 'area_type_id', this.tabFormData.applicant_present_area_type_id)
        this.$set(this.presentAddress, 'division_id', this.tabFormData.applicant_present_division_id)
        this.$set(this.presentAddress, 'district_id', this.tabFormData.applicant_present_district_id)
        this.$set(this.presentAddress, 'city_corporation_id', this.tabFormData.applicant_present_city_corporation_id)
        this.$set(this.presentAddress, 'paurashava_id', this.tabFormData.applicant_present_pauroshoba_id)
        this.$set(this.presentAddress, 'upazila_id', this.tabFormData.applicant_present_upazila_id)
        this.$set(this.presentAddress, 'union_id', this.tabFormData.applicant_present_union_id)
        this.$set(this.presentAddress, 'address_details_en', this.tabFormData.applicant_present_village_name)
        this.$set(this.presentAddress, 'address_details_bn', this.tabFormData.applicant_present_village_name_bn)
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        yesNoList () {
            return [
                { text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
                { text: this.currentLocale === 'en' ? 'No' : 'না', value: 2, text_en: 'No', text_bn: 'না' }
            ]
        },
        fiscalYearList () {
            return this.$store.state.CommonService.commonObj.fiscalYearList
        }
    },
    watch: {
        'formData.same_as_permanent': function (newVal, oldValue) {
            if (newVal !== oldValue) {
              this.setPresentasPermanent(newVal)
            }
        }
    },
    methods: {
        onFileChange (event, property) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.formData[property] = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.formData[property] = ''
            }
        },
        setPresentasPermanent (value) {
            if (value) {
                // present address
                this.$set(this.presentAddress, 'area_type_id', this.address.area_type_id)
                this.$set(this.presentAddress, 'division_id', this.address.division_id)
                this.$set(this.presentAddress, 'district_id', this.address.district_id)
                this.$set(this.presentAddress, 'city_corporation_id', this.address.city_corporation_id)
                this.$set(this.presentAddress, 'paurashava_id', this.address.paurashava_id)
                this.$set(this.presentAddress, 'upazila_id', this.address.upazila_id)
                this.$set(this.presentAddress, 'union_id', this.address.city_corporation_id)
                this.$set(this.presentAddress, 'address_details_en', this.address.address_details_en)
                this.$set(this.presentAddress, 'address_details_bn', this.address.address_details_bn)
            } else {
                // present address
                if (this.formData.id < 0) {
                    this.$set(this.presentAddress, 'area_type_id', 0)
                    this.$set(this.presentAddress, 'division_id', 0)
                    this.$set(this.presentAddress, 'district_id', 0)
                    this.$set(this.presentAddress, 'city_corporation_id', 0)
                    this.$set(this.presentAddress, 'paurashava_id', 0)
                    this.$set(this.presentAddress, 'upazila_id', 0)
                    this.$set(this.presentAddress, 'union_id', 0)
                    this.$set(this.presentAddress, 'address_details_en', '')
                    this.$set(this.presentAddress, 'address_details_bn', '')
                }
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                let result
                const data = Object.assign({}, this.formData, { status: this.status, parent_id: this.parentId })
                if (this.formData.id) {
                  result = await RestApi.putData(lrcpnServiceBaseUrl, acidImportClearanceApplicantInfoUpdateApi + '/' + this.formData.id, data)
                } else {
                  result = await RestApi.postData(lrcpnServiceBaseUrl, acidImportClearanceApplicantInfoStoreApi, data)
                }
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                }
            }
        },
        receivePermanentAddress (address) {
            this.address = address
            this.formData = Object.assign({}, this.formData, {
              applicant_permanent_area_type_id: this.address.area_type_id,
              applicant_permanent_division_id: this.address.division_id,
              applicant_permanent_district_id: this.address.district_id,
              applicant_permanent_city_corporation_id: this.address.city_corporation_id,
              applicant_permanent_pauroshoba_id: this.address.paurashava_id,
              applicant_permanent_upazila_id: this.address.upazila_id,
              applicant_permanent_union_id: this.address.union_id,
              applicant_permanent_village_name: this.address.address_details_en,
              applicant_permanent_village_name_bn: this.address.address_details_bn
            })
        },
        receivePresentAddress (address) {
            this.presentAddress = address
            this.formData = Object.assign({}, this.formData, {
              applicant_present_area_type_id: this.address.area_type_id,
              applicant_present_division_id: this.address.division_id,
              applicant_present_district_id: this.address.district_id,
              applicant_present_city_corporation_id: this.address.city_corporation_id,
              applicant_present_pauroshoba_id: this.address.paurashava_id,
              applicant_present_upazila_id: this.address.upazila_id,
              applicant_present_union_id: this.address.union_id,
              applicant_present_village_name: this.address.address_details_en,
              applicant_present_village_name_bn: this.address.address_details_bn
            })
        },
        receiveAddMoreAddress (address) {
            this.formDataAddMore.address = address
        },
        isNumber (event) {
            helpers.isNumber(event)
        },
        async getExternalUserInfo () {
            this.isExternalUserLoading = true
            var result
            result = await RestApi.postData(authServiceBaseUrl, 'user-management/user/get-user-details', this.search)
            if (result.success) {
                const data = {
                    applicant_name: result.data.name,
                    applicant_name_bn: result.data.name_bn,
                    applicant_mobile: result.data.mobile,
                    applicant_email: result.data.email,
                    user_id: result.data.id
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = true
            } else {
                const data = {
                    applicant_name: '',
                    applicant_name_bn: '',
                    applicant_mobile: '',
                    applicant_email: '',
                    user_id: null
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = false
            }
            this.isExternalUserLoading = false
        }
    }
}
</script>
